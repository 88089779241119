import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ArticleList from 'feature/ArticleList/ArticleList';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { objectToQuery } from 'assets/js/helper';
import Preloader from 'components/Preloader';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Modal, InputLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';

import {
  deleteArticle,
  publishArticle,
  unPublishArticle,
  getArticleList,
  getArticleTargets,
  getUsersAuthorsList,
} from '../../request';

let debounce = null;

const useStyles = makeStyles((theme) => ({
  formControlSite: {
    margin: theme.spacing(1),
    minWidth: 150,
    paddingTop: 7,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    paddingBottom: 10,
  },
  formControlAuthor: {
    margin: theme.spacing(1),
    minWidth: 150,
    paddingBottom: 8,
  },
  tools: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px 20px',
  },
  search: {
    paddingBottom: 9,
  },
  filterWrap: {
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  filterWrapBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  filterDate: {
    marginTop: 0,
  },
}));

const Actions = (props) => {
  const {
    slug,
    is_published,
    handleGetArticleList,
    handleGoToEdit,
    url,
    baseUrl,
  } = props;
  const [isOpenAlertDeleteModal, setOpenAlertDeleteModal] = useState(false);
  const deleteArticlesAllowed = useSelector((state) =>
    state?.auth?.all_permissions.find(
      (el) => el.slug === 'deleteArticlesAllowed'
    )
  );

  const handleDelateArticle = async () => {
    const { res } = await deleteArticle(slug);
    if (res.status == 204) {
      handleGetArticleList();
    }
  };

  const handleUnPublishArticle = async () => {
    const { res } = await unPublishArticle(slug);
    if (res.status == 200) {
      handleGetArticleList();
    }
  };

  const handlePublishArticle = async () => {
    const { res } = await publishArticle(slug);
    if (res.status == 200) {
      handleGetArticleList();
    }
  };

  const handleGoToClient = () => {
    window.open(`${baseUrl}/${url}`, '_blank');
  };

  return (
    <>
      <Modal
        open={isOpenAlertDeleteModal}
        onBackdropClick={() => setOpenAlertDeleteModal(false)}
        style={{
          display: 'grid',
          placeContent: 'center',
        }}
      >
        <Paper
          style={{
            width: 400,
            alignSelf: 'center',
          }}
        >
          <Alert
            variant="outlined"
            severity="error"
            action={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    handleDelateArticle(slug);
                    setOpenAlertDeleteModal(false);
                  }}
                >
                  Удалить
                </Button>
                <Button
                  onClick={() => {
                    setOpenAlertDeleteModal(false);
                  }}
                >
                  Отмена
                </Button>
              </div>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              Вы уверены что хотите удалить?
            </div>
          </Alert>
        </Paper>
      </Modal>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        }}
      >
        <Tooltip title="Редактировать" aria-label="add">
          <IconButton
            color="primary"
            aria-label="remove papper"
            onClick={() => handleGoToEdit(slug)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>{' '}
        <Tooltip title="Перейти на Сайт" aria-label="add">
          <IconButton
            color="primary"
            aria-label="remove paper"
            onClick={handleGoToClient}
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>{' '}
        {is_published ? (
          <Tooltip title="Снять с публикации" aria-label="add">
            <span>
              <IconButton
                color="primary"
                aria-label="Visibility off paper"
                onClick={handleUnPublishArticle}
              >
                <VisibilityOffIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="опубликовать" aria-label="add">
            <IconButton
              color="primary"
              aria-label="Visibility on paper"
              onClick={handlePublishArticle}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        )}
        {deleteArticlesAllowed && (
          <Tooltip title="Удалить" aria-label="add">
            <IconButton
              color="secondary"
              aria-label="remove papper"
              onClick={() => setOpenAlertDeleteModal(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
};

const Articles = () => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const initPage =
    query.get('page') && typeof Number(query.get('page')) === 'number'
      ? Number(query.get('page'))
      : 1;
  const initPerPage =
    query.get('per_page') && typeof Number(query.get('per_page')) === 'number'
      ? Number(query.get('per_page'))
      : 100;
  const initTarget =
    query.get('target_id') && typeof Number(query.get('target_id')) === 'number'
      ? Number(query.get('target_id'))
      : null;

  // Удаляем параметры не относящиеся к фильтрам
  query.delete('page');
  query.delete('per_page');
  query.delete('target_id');

  const [articles, setArticles] = useState();
  const [page, setPage] = React.useState(initPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(initPerPage);
  const [targetId, setTargetId] = React.useState(initTarget);
  const [sitePermissions, setSitePermissions] = React.useState([]);
  const [filters, setFilters] = useState(Object.fromEntries(query.entries()));
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [authorList, setAuthorList] = useState([]);
  const [baseUrl, setBaseUrl] = useState(sitePermissions[0]?.url);
  const permissions = useSelector((state) =>
    state?.auth?.sitePermissions.map(({ name }) => name)
  );
  const [loadingArticles, setLoadingArticles] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    setBaseUrl(sitePermissions.find(({ id }) => id === targetId)?.url);
  }, [targetId, sitePermissions]);

  useEffect(() => {
    setLoadingArticles(true);
    getArticleTargets()
      .then((result) => {
        const perm = result?.filter(({ name }) => permissions?.includes(name));
        setSitePermissions(perm);
        if (perm?.length === 0) {
          history.push(
            `/admin/articles?page=${page}&per_page=${rowsPerPage}&target_id=null${objectToQuery(
              filters
            )}`
          );
          return;
        }
        const validTargetId =
          perm.find(({ id }) => targetId === id)?.id ||
          perm[perm.length - 1].id;
        setTargetId(validTargetId);

        history.push(
          `/admin/articles?page=${page}&per_page=${rowsPerPage}&target_id=${validTargetId}${objectToQuery(
            filters
          )}`
        );
      })
      .finally(() => setLoadingArticles(false));
  }, []);

  useEffect(() => {
    let filterDate = '';

    if (dateFrom && dateTo) {
      filterDate = `&pub_date_at_local__gt=${dateFrom}&pub_date_at_local__lt=${dateTo}`;
    } else if (dateFrom && !dateTo) {
      filterDate = `&pub_date_at_local__gt=${dateFrom}`;
    } else if (!dateFrom && dateTo) {
      filterDate = `&pub_date_at_local__lt=${dateTo}`;
    }

    if (!targetId || !page || !rowsPerPage) return;

    history.push(
      `/admin/articles?page=${page}&per_page=${rowsPerPage}&target_id=${targetId}${filterDate}${objectToQuery(
        filters
      )}`
    );
  }, [dateFrom, dateTo]);

  useEffect(() => {
    handleGetArticleList();
  }, [location.search]);

  const handleGetArticleList = () => {
    if (!location.search) return;
    async function fetchData() {
      setLoadingArticles(true);
      const res = await getArticleList(location.search + '&query={id,slug,title,is_published,pub_date_at,image_cover{id},video_cover,shows}');
      const users = await getUsersAuthorsList(`?target_id=${targetId}&query={id,first_name,last_name}`);
      setAuthorList([{ id: 0, first_name: '', last_name: 'Все' }, ...users]);
      setArticles(res);
      setLoadingArticles(false);
    }
    fetchData();
  };

  const handleChangeRowsPerPage = (value) => {
    history.push(
      `/admin/articles?page=1&per_page=${value}&target_id=${targetId}${objectToQuery(
        filters
      )}`
    );
    setRowsPerPage(value);
  };
  const HandleChangePage = (nextPage) => {
    let filterDate = '';

    if (dateFrom && dateTo) {
      filterDate = `&pub_date_at_local__gt=${dateFrom}&pub_date_at_local__lt=${dateTo}`;
    } else if (dateFrom && !dateTo) {
      filterDate = `&pub_date_at_local__gt=${dateFrom}`;
    } else if (!dateFrom && dateTo) {
      filterDate = `&pub_date_at_local__lt=${dateTo}`;
    }

    history.push(
      `/admin/articles?page=${nextPage}&per_page=${rowsPerPage}&target_id=${targetId}${filterDate}${objectToQuery(
        filters
      )}`
    );
    setPage(nextPage);
  };

  const handlerChangeFilterDateFrom = (dateFrom) => {
    setDateFrom(dayjs(dateFrom).startOf('day').utc().valueOf());
  };

  const handlerChangeFilterDateTo = (dateTo) => {
    setDateTo(dayjs(dateTo).endOf('day').utc().valueOf());
  };

  const handleChangeTarget = ({ target }) => {
    setFilters({ ...filters, author__id: 0 });
    history.push(
      `/admin/articles?page=1&per_page=${rowsPerPage}&target_id=${
        target.value
      }${objectToQuery({ ...filters, author__id: 0 })}`
    );
    setPage(1);
    setTargetId(target.value);
  };

  const handleChangeAuthor = ({ target }) => {
    setFilters({ ...filters, author__id: target.value });
    history.push(
      `/admin/articles?page=1&per_page=${rowsPerPage}&target_id=${targetId}${objectToQuery(
        { ...filters, author__id: target.value }
      )}`
    );
    setPage(1);
  };

  const handleChangeSearch = ({ target }) => {
    setFilters({ ...filters, search: target.value });
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      history.push(
        `/admin/articles?page=1&per_page=${rowsPerPage}&target_id=${targetId}${objectToQuery(
          { ...filters, search: target.value }
        )}`
      );
      setPage(1);
    }, 600);
  };

  const handleGoToEdit = (slug) => window.open(`/admin/articles/edit/${slug}`);

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <b>Список статей</b>
      </Paper>
      <Paper style={{ padding: 15 }}>
        <div className={classes.tools}>
          <Button
            onClick={() => history.push('/admin/articles/new')}
            variant="contained"
            color="primary"
          >
            Добавить статью
          </Button>
          <TextField
            label="Поиск"
            className={classes.search}
            value={filters.search}
            onChange={handleChangeSearch}
          />
          {/*<div className={classes.filterWrap}>*/}
          {/*  */}
          {/*</div>*/}
          <div className={classes.filterWrapBox}>
            <KeyboardDatePicker
              className={classes.filterDate}
              variant="inline"
              format="dd.MM.yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Дата публикации (c)"
              value={dateFrom}
              onChange={handlerChangeFilterDateFrom}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <IconButton
              className={classes.mr15}
              onClick={() => setDateFrom(null)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.filterWrapBox}>
            <KeyboardDatePicker
              className={classes.filterDate}
              variant="inline"
              format="dd.MM.yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Дата публикации (по)"
              value={dateTo}
              onChange={handlerChangeFilterDateTo}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <IconButton
              className={classes.mr15}
              onClick={() => setDateTo(null)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <FormControl className={classes.formControlSite}>
            {targetId && (
              <Select
                labelId="select-site-label"
                value={targetId}
                onChange={handleChangeTarget}
              >
                {sitePermissions.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <FormControl className={classes.formControlAuthor}>
            <InputLabel>Автор</InputLabel>
            <Select
              labelId="select-author-label"
              value={filters?.author__id || 0}
              label="Автор"
              onChange={handleChangeAuthor}
            >
              {authorList &&
                authorList.map(({ id, first_name, last_name }) => (
                  <MenuItem key={id} value={id}>
                    {`${last_name} ${first_name}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <Divider />

        {loadingArticles ? (
          <Preloader />
        ) : (
          <ArticleList
            {...articles}
            page={page - 1}
            baseUrl={baseUrl}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={HandleChangePage}
            Actions={Actions}
            handleGoToEdit={handleGoToEdit}
            handleGetArticleList={handleGetArticleList}
          />
        )}
      </Paper>
    </>
  );
};

export default Articles;
